import type { Condition } from '@repo/api-gw-sdk';
import { useMemo } from 'react';

import OptionMenu from '@/@core/components/option-menu';
import type { OptionType } from '@/@core/components/option-menu/types';
import { CombineOperator } from '@/types/advanceFilter';

import type {
  ConditionEditorOptions,
  LocationIndicator,
} from './ConditionEditor';
import { isGroup as _isGroup } from './ConditionEditor';

export const ConditionMenu = ({
  condition,
  parent,
  options,
  onVisibilityChange,
  onChangeParent,
  markParentLocation,
}: {
  condition: Condition;
  parent: Condition;
  options: ConditionEditorOptions;
  onVisibilityChange: (visible: boolean) => void;
  onChangeParent: (parent: Condition) => void;
  markParentLocation?: (location: LocationIndicator | undefined) => void;
}) => {
  const isGroup = useMemo(() => _isGroup(condition), [condition]);
  const conditionIndex = useMemo(
    () => parent.conditions?.findIndex((c) => c === condition) ?? 0,
    [condition, parent]
  );
  const onDelete = () => {
    onChangeParent({
      ...parent,
      conditions: parent.conditions?.filter((c) => c !== condition) ?? [],
    });
  };
  const onWrapWithGroup = () => {
    // TODO(MoLow): this function needs extensive testing:
    // 1. test wrapping group gets its opposite operator
    // 2. test wrapping non group gets parent opposite operator
    // make sure both cases add an empty condition to the new group
    const refOperator = isGroup
      ? (condition.operator as CombineOperator)
      : (parent.operator as CombineOperator);
    const operator =
      refOperator === CombineOperator.And
        ? CombineOperator.Or
        : CombineOperator.And;
    onChangeParent({
      ...parent,
      conditions:
        parent.conditions?.map((c) =>
          c === condition ? { operator, conditions: [c, { operator: '' }] } : c
        ) ?? [],
    });
  };
  const moveToIndex = (index: number) => {
    // TODO(MoLow): this function needs extensive testing:
    // 1. test top-most condition up is disabled
    // 2. test bottom-most condition down is disabled
    // make sure both directions move to the correct place
    const conditions = parent.conditions?.slice() ?? [];
    conditions.splice(conditionIndex, 1);
    conditions.splice(index, 0, condition);
    onChangeParent({ ...parent, conditions });
  };
  const addSibling = (index: number) => {
    // TODO(MoLow): this function needs extensive testing:
    // make sure adding above and below works as expected
    const conditions = parent.conditions?.slice() ?? [];
    conditions.splice(index, 0, { operator: '' });
    onChangeParent({ ...parent, conditions });
  };

  return (
    <OptionMenu
      icon='material-symbols-more-horiz'
      onOptionSelected={() => markParentLocation?.(undefined)}
      onToggle={onVisibilityChange}
      options={
        [
          options.supportGrouping && {
            text: `Create Group`,
            icon: (
              <i className='material-symbols-light-variable-add-outline w-[20px] h-[20px] mr-[8px]' />
            ),
            menuItemProps: {
              onClick: onWrapWithGroup,
            },
          },
          options.supportGrouping && { divider: true },
          {
            text: (
              <>
                <span className='mr-[8px]'>Add Condition</span>
                <i className='material-symbols-chevron-right-rounded w-[20px] h-[20px] ml-auto' />
              </>
            ),
            icon: (
              <i className='material-symbols-add-rounded w-[20px] h-[20px] mr-[8px]' />
            ),
            subMenu: [
              {
                text: `Above`,
                menuItemProps: {
                  onMouseEnter: () =>
                    markParentLocation?.({
                      index: conditionIndex,
                      location: 'above',
                    }),
                  onMouseLeave: () => markParentLocation?.(undefined),
                  onClick: () => addSibling(conditionIndex),
                },
                icon: (
                  <i className='material-symbols-vertical-align-top w-[20px] h-[20px] mr-[8px]' />
                ),
              },
              {
                text: `Below`,
                menuItemProps: {
                  onMouseEnter: () =>
                    markParentLocation?.({
                      index: conditionIndex,
                      location: 'below',
                    }),
                  onMouseLeave: () => markParentLocation?.(undefined),
                  onClick: () => addSibling(conditionIndex + 1),
                },
                icon: (
                  <i className='material-symbols-vertical-align-bottom w-[20px] h-[20px] mr-[8px]' />
                ),
              },
            ],
          },
          {
            text: (
              <>
                <span className='mr-[8px]'>
                  Move {isGroup ? 'Group' : 'Condition'}
                </span>
                <i className='material-symbols-chevron-right-rounded w-[20px] h-[20px] ml-auto' />
              </>
            ),
            icon: (
              <i className='material-symbols-swap-vert-rounded w-[20px] h-[20px] mr-[8px]' />
            ),
            subMenu: [
              {
                text: `Up`,
                menuItemProps: {
                  disabled: conditionIndex === 0,
                  onMouseEnter: () =>
                    markParentLocation?.({
                      index: conditionIndex - 1,
                      location: 'above',
                    }),
                  onMouseLeave: () => markParentLocation?.(undefined),
                  onClick: () => moveToIndex(conditionIndex - 1),
                },
                icon: (
                  <i className='material-symbols-arrow-warm-up w-[20px] h-[20px] mr-[8px]' />
                ),
              },
              {
                text: `Down`,
                menuItemProps: {
                  disabled:
                    conditionIndex === (parent.conditions?.length ?? 0) - 1,
                  onMouseEnter: () =>
                    markParentLocation?.({
                      index: conditionIndex + 1,
                      location: 'below',
                    }),
                  onMouseLeave: () => markParentLocation?.(undefined),
                  onClick: () => moveToIndex(conditionIndex + 1),
                },
                icon: (
                  <i className='material-symbols-arrow-cool-down w-[20px] h-[20px] mr-[8px]' />
                ),
              },
            ],
          },
          { divider: true },
          {
            text: `Delete ${isGroup ? 'Group' : 'Condition'}`,
            icon: (
              <i className='material-symbols-close-rounded w-[20px] h-[20px] mr-[8px]' />
            ),
            menuItemProps: {
              onClick: onDelete,
            },
          },
        ].filter(Boolean) as OptionType[]
      }
    />
  );
};
