import { styled } from '@mui/material';
import type { AccordionProps } from '@mui/material/Accordion';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import type { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: 'none',
  backgroundColor: 'transparent',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<i className='material-symbols-arrow-right-rounded' />}
    {...props}
  />
))(() => ({
  margin: 0,
  padding: 0,
  height: '40px',
  minHeight: '40px',
  backgroundColor: 'transparent',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    paddingLeft: '16px',
  },
  '&.Mui-expanded': {
    minHeight: '40px',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '16px 36px',
}));

export { Accordion, AccordionSummary, AccordionDetails };
